import React, { forwardRef } from "react";

import FieldWrapper from "../FieldWrapper";

import { InputProps } from "./types";
import * as Styled from "./styles";

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      placeholder,
      disabled,
      error,
      suffix,
      required,
      ...rest
    },
    ref
  ) => (
    <FieldWrapper {...{ label, disabled, error, suffix, required }}>
      <Styled.Input
        {...{ ref, disabled, required, ...rest }}
        withSuffix={!!suffix}
        placeholder={placeholder || " "}
      />
    </FieldWrapper>
  )
);

export default Input;
